import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';

interface OtpInputComponentProps {
  otp: string;
  setOtp: (otp: string) => void;
}

const OtpInputComponent: React.FC<OtpInputComponentProps> = ({
  otp,
  setOtp,
}) => {
  const [inputSize, setInputSize] = useState(60);

  useEffect(() => {
    const handleResize = () => {
      setInputSize(window.innerWidth >= 640 ? 60 : 50);
    };

    // Set initial size
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={6}
      containerStyle={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
      inputType="number"
      shouldAutoFocus={true}
      inputStyle={{
        border: '1px solid transparent',
        outline: 'none',
        borderRadius: '12px',
        width: `${inputSize}px`,
        height: `${inputSize}px`,
        fontSize: '16px',
        color: '#000',
        fontWeight: '500',
        caretColor: 'black',
        backgroundColor: '#F1F5F9',
      }}
      renderSeparator={<span></span>}
      renderInput={(props) => <input {...props} inputMode="numeric" />}
    />
  );
};

export default OtpInputComponent;
