import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Logo from '../../components/core/logo';
import ResetPassword from '../../components/auth/ResetPassword';
import { Icons } from '../../assets/icons';
import { toast } from 'sonner';
import React from 'react';

const PasswordReset = () => {
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [emailAddress, setEmail] = useState('');
  const [resetToken, setToken] = useState('');

  useEffect(() => {
    if (location.pathname.split('/')[2]) {
      setToken(location.pathname.split('/')[2]);
      setEmail(location.pathname.split('/')[3]);
    }
  }, [emailAddress, location.pathname, resetToken]);

  const verifyToken = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axiosPrivate.post(`user/validate-reset-password`, {
        emailAddress,
        resetToken,
      });
      if (res && res.data) {
        setLoading(false);
        setValid(true);
      }
    } catch (err: any) {
      setLoading(false);
      toast.error(
        err.response?.data?.responseMessage || 'The token has expired'
      );
    }
  }, [axiosPrivate, emailAddress, resetToken]); // Add dependencies as needed

  useEffect(() => {
    if (resetToken) {
      verifyToken();
    }
  }, [resetToken, verifyToken]);

  if (!valid && loading) {
    return (
      <div className="flex items-center justify-center w-full h-screen max-w-sm gap-3 px-4 mx-auto mt-10">
        <Icons.Spinner />
      </div>
    );
  }

  // {
  //   !valid && !loading && (
  //     <div id="resetPassword">
  //       <div className="container">
  //         <h2>The token has expired</h2>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="sm:bg-[#f9fafb] h-screen flex justify-center items-center overflow-auto pt-20 pb-20">
      <div className="flex flex-col items-center justify-center w-full max-w-md gap-3 px-4 mx-auto bg-white sm:p-8 rounded-xl">
        <Link to="/">
          <Logo type="icon" className="w-10 h-16" />
        </Link>
        <ResetPassword
          resetToken={resetToken}
          emailAddress={emailAddress}
          isValid={valid}
        />
      </div>
    </div>
  );
};

export default PasswordReset;
