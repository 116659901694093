import { Dispatch, ReactNode, createContext, useReducer } from 'react';
import React from 'react';
import { Asset, IUser } from '../interface/auth/api';

interface State {
  users: IUser | null;
  isDarkmode: boolean;
  usersError: any; // Define the type of usersError as needed
  assets: Asset[];
  assetsError: any; // Define the type of assetsError as needed
  likedAssets: Asset[];
  likedAssetsError: any; // Define the type of likedAssetsError as needed
  pageLoad: boolean;
}

const INITIAL_STATE: State = {
  users: null,
  isDarkmode: false,
  usersError: null,
  assets: [],
  assetsError: null,
  likedAssets: [],
  likedAssetsError: null,
  pageLoad: false,
};

type Action =
  | { type: 'SET_USER'; payload: IUser }
  | { type: 'SET_USER_FAIL'; payload: any }
  | { type: 'SET_ASSETS'; payload: Asset[] }
  | { type: 'SET_ASSETS_FAIL'; payload: any }
  | { type: 'SET_LIKED_ASSETS'; payload: Asset[] }
  | { type: 'SET_LIKED_ASSETS_FAIL'; payload: any }
  | { type: 'SET_PAGELOAD'; payload: boolean }
  | { type: 'SET_MULTIPLE'; payload: Partial<State> }
  | { type: 'TOGGLE_DARK_MODE'; payload: boolean };

export interface GlobalContextType {
  users: IUser | null;
  isDarkmode: boolean;
  usersError: any; // Define the type of usersError as needed
  assets: Asset[];
  assetsError: any; // Define the type of assetsError as needed
  likedAssets: Asset[];
  likedAssetsError: any; // Define the type of likedAssetsError as needed
  pageLoad: boolean;
  dispatch: Dispatch<Action>;
}

export const GlobalContext = createContext<GlobalContextType | null>(null);

const GlobalReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        users: action.payload,
      };
    case 'SET_USER_FAIL':
      return {
        ...state,
        usersError: action.payload,
      };
    case 'SET_ASSETS':
      return {
        ...state,
        assets: action.payload,
      };
    case 'SET_ASSETS_FAIL':
      return {
        ...state,
        assetsError: action.payload,
      };
    case 'SET_LIKED_ASSETS':
      return {
        ...state,
        likedAssets: action.payload,
      };
    case 'SET_LIKED_ASSETS_FAIL':
      return {
        ...state,
        likedAssetsError: action.payload,
      };
    case 'SET_PAGELOAD':
      return {
        ...state,
        pageLoad: action.payload,
      };
    case 'SET_MULTIPLE':
      return {
        ...state,
        ...action.payload,
      };
    case 'TOGGLE_DARK_MODE':
      return {
        ...state,
        isDarkmode: action.payload,
      };
    default:
      return state;
  }
};

interface GlobalContextProviderProps {
  children: ReactNode;
}

export const GlobalContextProvider = ({
  children,
}: GlobalContextProviderProps) => {
  const [state, dispatch] = useReducer(GlobalReducer, INITIAL_STATE);

  return (
    <GlobalContext.Provider
      value={{
        users: state.users,
        usersError: state.usersError,
        assets: state.assets,
        assetsError: state.assetsError,
        likedAssets: state.likedAssets,
        likedAssetsError: state.likedAssetsError,
        pageLoad: state.pageLoad,
        isDarkmode: state.isDarkmode,
        dispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
