import React, { useState, useEffect, useCallback } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import SavedAsset from '../../components/dashboard/SavedAsset';
import ErrorMessage from '../../components/ui/error-fallback';
import { Button } from '../../components/ui/button';
import { useNavigate } from 'react-router-dom';

const SavedAssetPage = () => {
  const axiosPrivate = useAxiosPrivate();
  const [savedAssets, setSavedAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const getSavedAsset = useCallback(async () => {
    try {
      setIsLoading(true);

      let payload = {
        pageNumber: 0,
        pageSize: 50,
        category: 5,
      };

      let res = await axiosPrivate.post(`asset/search-assets`, payload);
      setSavedAssets(res.data.data);
    } catch (err: any) {
      setError(true);
      console.error(err.response?.data?.responseMessage || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  }, [axiosPrivate]);

  useEffect(() => {
    getSavedAsset();
  }, [getSavedAsset]);

  if (error) {
    return (
      <ErrorMessage
        message={'Error getting saved asset'}
        refetch={getSavedAsset}
      />
    );
  }

  if (!isLoading && !savedAssets.length) {
    return (
      <div className="flex flex-col items-center justify-center h-[75vh] gap-3">
        <p className="-mt-5 text-base">{'There are no saved assets'}</p>
        <Button
          variant="default"
          className=""
          onClick={() => {
            navigate(-1);
          }}
        >
          Go Back
        </Button>
      </div>
    );
  }

  return <SavedAsset assets={savedAssets} isLoading={isLoading} />;
};

export default SavedAssetPage;
