import { Outlet } from 'react-router-dom';
import Header from '../../components/core/nav/Header';
import Sidebar from '../../components/core/nav/Sidebar';
import { useGetUser } from '../../helpers/api/useAsset';
import React from 'react';

const AppHome = () => {
  const { payload } = useGetUser();
  const user = payload;

  return (
    <>
      <div className="flex h-screen mx-auto overflow-hidden max-w-[1800px]">
        {/* <!-- ===== Sidebar Start ===== --> */}
        <Sidebar user={user} />
        {/* <!-- ===== Sidebar End ===== --> */}

        {/* <!-- ===== Content Area Start ===== --> */}
        <div className="relative flex flex-col flex-1 overflow-x-hidden ">
          {/* <!-- ===== Header Start ===== --> */}
          <Header user={user} />
          {/* <!-- ===== Header End ===== --> */}

          {/* <!-- ===== Main Content Start ===== --> */}
          <div className="w-full pb-40 mx-auto md:pr-4 md:pb-28">
            <Outlet />
          </div>
          {/* <!-- ===== Main Content End ===== --> */}
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </>
  );
};

export default AppHome;
