import { toast } from 'sonner';
import { OnboardContext } from '../../../context/OnboardContext';
import { useContext, useRef } from 'react';
import { Button } from '../../ui/button';
import { cn } from '../../../lib/utils';
import { Icons } from '../../../assets/icons';

const FileUpload = ({ next, getBase64 }) => {
  const { fileToBeUploaded, dispatch } = useContext(OnboardContext);
  const fileInput = useRef(null);

  // const handleClick = () => {
  //   fileInput.current.click();
  // };

  const handleBack = (e) => {
    e.preventDefault();
    next('requiredSteps');
  };

  const upload = async (e) => {
    try {
      getBase64(e.target.files[0], async (base64) => {
        let payload = {
          documentId: fileToBeUploaded.id,
          document: base64,
        };
        dispatch({ type: 'UPDATE_DOC', payload });
        dispatch({ type: 'DOCS_UPLOADED', payload: fileToBeUploaded });
        next('requiredSteps');
      });
      e.value = null;
    } catch (err) {
      toast.error(err.response?.data?.responseMessage || 'An error occurred');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full max-w-[459px] gap-6 mx-auto">
      <div className="flex flex-col w-full gap-3 text-center">
        <h4 className="text-2xl font-semibold text-[#0B2253]">
          Upload your {fileToBeUploaded?.name || 'Document'}
        </h4>
        <p className="text-sm text-[#061E38]">
          Please note that once you submit your document for review it cannot be
          changed.
        </p>
      </div>

      <label
        className={cn(
          'z-0 relative flex flex-col gap-2 h-[162px] justify-center items-center w-full p-4 transition border-2 bg-[#FFFFFF] border-[#ECF4FB] border-dashed rounded-xl appearance-none cursor-pointer focus:outline-none'
        )}
      >
        <>
          <Icons.UploadIcon />
          <div className="flex items-center flex-col text-sm sm:text-[15px] gap-2 mx-auto">
            <span className="text-center ">
              Drop your document here or
              <span className="text-[#27A376]"> browse file</span>
            </span>
          </div>

          <input
            hidden
            ref={fileInput}
            type="file"
            name=""
            id=""
            onChange={(e) => upload(e)}
          />
        </>
      </label>
      <Button onClick={(e) => handleBack(e)} className="w-40 rounded-lg">
        Back
      </Button>
    </div>
  );
};

export default FileUpload;
