/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'sonner';
import { cva } from 'class-variance-authority';
import { cn } from '../../lib/utils';
import { Icons } from '../../assets/icons';
import { useLogin } from '../../helpers/api/useAuth';
import { redirectHelper } from '../../utils/helper';
import React from 'react';
import Logo from '../core/logo';
import { Button } from '../ui/button';
import { Label } from '../ui/label';
import { Input } from '../ui/input';

export const InputVariants = cva('relative', {
  variants: {
    iconPosition: {
      left: ' absolute left-3 top-1/2 -translate-y-1/2 transform text-muted-foreground',
      right:
        ' absolute left-auto right-2 top-1/2 -translate-y-1/2 transform text-muted-foreground',
    },
  },
  defaultVariants: {
    iconPosition: 'left',
  },
});

const Login = () => {
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [credentials, setCredentials] = useState({
    companyName: '',
    pushNotificationId: '3',
    username: '',
    password: '',
  });
  const {
    mutate,
    isLoading: isLoginLoading,
    isError: isLoginError,
    isSuccess: isLoginSuccess,
    errorMessage,
    data,
  } = useLogin(credentials.username);
  const loginRes = data?.data;
  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    mutate(credentials);
  };

  const redirect = useCallback(
    (data: any) => {
      redirectHelper(data, navigate);
    },
    [navigate]
  );

  useEffect(() => {
    if (isLoginSuccess) {
      redirect(loginRes?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginSuccess, redirect]);

  useEffect(() => {
    if (isLoginError) {
      toast.error('Login Error', {
        description: errorMessage || 'Error Logging in',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginError]);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleChange = (e: any) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const ForgotPassword = () => {
    navigate('../register', {
      state: {
        section: 'forgotPassword',
      },
    });
  };

  return (
    <div className="flex flex-col items-center justify-center w-full max-w-md gap-4 px-4 py-10 mx-auto bg-white sm:px-8 rounded-xl">
      <Link to="/">
        <Logo type="icon" className="w-10 h-16" />
      </Link>
      <div className="w-full text-center">
        <h4 className="text-2xl font-semibold">Login To Your Account</h4>
        <p>Let's get you signed in</p>
      </div>
      <form onSubmit={onSubmit} className="flex flex-col w-full gap-2">
        <div className="w-full">
          <Label
            htmlFor="email"
            className="text-[13px] text-[#A2A1A8] font-medium"
          >
            Email
          </Label>
          <Input
            id="username"
            type="email"
            placeholder="Enter your email"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="relative w-full">
          <Label
            htmlFor="password"
            className="text-[13px] text-[#A2A1A8] font-medium"
          >
            Password
          </Label>
          <Input
            id="password"
            placeholder="********"
            onChange={(e) => handleChange(e)}
            type={passwordShown ? 'text' : 'password'}
            onClickEndIcon={togglePassword}
            endIcon={
              !passwordShown ? (
                <FontAwesomeIcon className="passwordIcon" icon={faEye} />
              ) : (
                <FontAwesomeIcon className="passwordIcon" icon={faEyeSlash} />
              )
            }
          />
        </div>
        <div className="flex flex-col w-full gap-2 mt-2 text-center">
          <Button
            type="submit"
            disabled={
              isLoginLoading ||
              credentials.username === '' ||
              credentials.password === ''
            }
            className={cn('text-sm w-full h-12')}
          >
            {!isLoginLoading ? (
              'Sign in'
            ) : (
              <Icons.Spinner className="w-6 h-6 bg-transparent animate-spin" />
            )}
          </Button>
          <p
            className="text-sm text-center text-blue-600 cursor-pointer"
            onClick={ForgotPassword}
          >
            Forgot Password
          </p>
          <p>
            Don't have an account?{' '}
            <Link to="/register" className="text-sm text-blue-600">
              Sign Up
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Login;

