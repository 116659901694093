import axios from 'axios';

const BASE = process.env.REACT_APP_URL;
const BASE_URL = `${BASE}/api/v1/`;

export default axios.create({
  baseURL: BASE_URL,
});

export const bareAxios = axios.create({
  baseURL: `${BASE}/api/`,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});
