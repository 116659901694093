import AssetDetails from '../../components/dashboard/AssetDetails';
import { useGetAssetById } from '../../helpers/api/useAsset';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorMessage from '../../components/ui/error-fallback';
import React from 'react';
import ContainerLayout from '../../components/core/containerLayout';
import { Button } from '../../components/ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const AssetDetailsPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { isLoading, isError, data, errorMessage, refetch } = useGetAssetById(
    params?.id as string
  );
  const details = data?.data;
  const goBack = () => {
    navigate(-1);
  };

  if (isLoading) {
    return (
      <ContainerLayout className="gap-0">
        <div className="w-full px-4 py-0 md:px-0">
          <Button id="top" variant="ghost" className="p-0" onClick={goBack}>
            <FontAwesomeIcon icon={faAngleLeft} /> Back
          </Button>
        </div>
        <div className="w-full px-4 mx-auto mt-2 md:px-0 animate-pulse">
          {/* Image Grid Skeleton */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-1 h-[300px] sm:h-[400px] xl:h-[500px]">
            {/* Main Image Skeleton */}
            <div className="col-span-1 row-span-1 overflow-hidden sm:col-span-2 sm:row-span-2">
              <div className="animate-pulse bg-gray-200 w-full h-[300px] sm:h-[400px] xl:h-[500px] md:rounded-r-none rounded-xl md:rounded-l-xl" />
            </div>

            {/* Grid Images Skeleton */}
            {[...Array(4)].map((_, index) => (
              <div
                key={index}
                className={`relative overflow-hidden md:block hidden ${
                  index === 1
                    ? 'sm:rounded-tr-xl'
                    : index === 3 && 'rounded-br-xl'
                }`}
              >
                <div
                  className={`
              w-full h-full bg-gray-200 max-h-[260px] 
              ${index === 1 ? 'sm:rounded-tr-xl' : ''}
              ${index === 3 ? 'rounded-br-xl' : ''}
            `}
                />
              </div>
            ))}
          </div>

          {/* Title Skeleton */}
          <div className="w-1/2 h-8 mt-6 bg-gray-200 rounded-full" />

          {/* Units and Location Skeleton */}
          <div className="flex mt-4 space-x-4">
            <div className="w-1/4 h-4 bg-gray-200 rounded-lg" />
            <div className="w-1/4 h-4 bg-gray-200 rounded-lg" />
          </div>

          {/* Price Skeleton */}
          <div className="w-1/3 h-6 mt-4 bg-gray-200 rounded-full" />

          {/* Description Skeleton */}
          <div className="mt-6 space-y-2">
            <div className="w-full h-4 bg-gray-200 rounded-lg" />
            <div className="w-full h-4 bg-gray-200 rounded-lg" />
            <div className="w-full h-4 bg-gray-200 rounded-lg" />
            <div className="w-full h-4 bg-gray-200 rounded-lg" />
            <div className="w-5/6 h-4 bg-gray-200 rounded-lg" />
            <div className="w-4/6 h-4 bg-gray-200 rounded-lg" />
          </div>
          <div className="mt-6 space-y-2">
            <div className="w-full h-4 bg-gray-200 rounded-lg" />
            <div className="w-full h-4 bg-gray-200 rounded-lg" />
            <div className="w-full h-4 bg-gray-200 rounded-lg" />
            <div className="w-full h-4 bg-gray-200 rounded-lg" />
            <div className="w-5/6 h-4 bg-gray-200 rounded-lg" />
            <div className="w-4/6 h-4 bg-gray-200 rounded-lg" />
          </div>
        </div>
      </ContainerLayout>
    );
  }

  if (isError || !details) {
    return <ErrorMessage message={errorMessage} refetch={refetch} />;
  }
  return (
    <AssetDetails
      assetDetails={details}
      isLoading={isLoading}
      refetch={refetch}
    />
  );
};

export default AssetDetailsPage;
