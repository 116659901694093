'use client';

import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm, Controller, type SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams } from 'react-router-dom';
import useNewsletterSubscription from '../../helpers/api/useSubscription';
import { cn } from '../../lib/utils';
import { Icons } from '../../assets/icons';
import React from 'react';

const images = [
  '/heroImage.png',
  '/heroImage2.png',
  '/heroImage3.png',
  '/heroImage4.png',
];

const delay = 2500;

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
});

interface FormValues {
  email: string;
}

export default function Hero() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  let refValue = searchParams.get('ref');
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
    },
  });

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false); // You can handle errors separately if needed
  };

  const {
    loading,
    error,
    success,
    errorMessage,
    onSubmit: mutate,
  } = useNewsletterSubscription(refValue != null ? String(refValue) : '');

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    mutate(data);
  };

  useEffect(() => {
    if (success.status) {
      reset({
        email: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success.status]);

  function Slideshow() {
    const [index, setIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, delay);

      return () => {
        clearInterval(interval);
      };
    }, []);

    return (
      <div className="relative overflow-hidden">
        {isLoading && (
          <div className="bg-gray-100 w-full sm:w-[656px] h-full sm:h-[596px] rounded-[35px] animate-pulse" />
        )}
        <div className=" w-full max-w-[656px] rounded-[35px]">
          {images.map((image: string, i: number) => (
            <img
              key={i}
              src={image}
              alt={`Slide ${i}`}
              className={` w-full sm:w-[656px] h-full sm:h-[596px] rounded-[35px]  transition ease-in-out duration-300
                ${i === index ? 'block' : 'hidden'}`}
              onLoad={handleImageLoad}
              onError={handleImageError}
              style={isLoading ? { display: 'none' } : {}}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="relative overflow-hidden bg-white">
      <div className="py-24">
        <div className="relative flex flex-col items-center justify-between gap-5 app-container lg:flex-row">
          <div className="sm:max-w-lg text-start sm:text-center lg:text-start">
            <h1 className="text-5xl font-bold leading-[60px] sm:leading-[80px] text-primary sm:text-6xl">
              Build your Real Estate portfolio a unit at a time.
            </h1>
            <p className="mt-4 text-base sm:text-xl text-primary">
              Buy part, invest and co-own real estate properties according to
              your financial strength.
            </p>
            <div className="flex flex-col gap-4 my-12">
              <span className="text-[#04093F] text-[15px]">
                Sounds Interesting?, Join the Beta Community for exclusive perks
                and rewards, receive offers, and get updates prior to launch...
              </span>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex items-stretch justify-start sm:justify-center lg:justify-start "
              >
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <>
                      <input
                        className={cn(
                          'bg-gray-100 rounded-lg rounded-r-none text-base leading-none text-gray-800 p-4 w-4/5 md:w-3/5 border border-transparent focus:outline-none focus:border-gray-500',
                          {
                            'border-red-600 focus:border-red-500 bg-white':
                              errors.email,
                          }
                        )}
                        type="email"
                        {...field}
                        placeholder="Enter email address"
                      />
                    </>
                  )}
                />
                <button
                  type="submit"
                  disabled={loading}
                  className="disabled:opacity-75 items-center text-center justify-center flex w-32 rounded-l-none hover:bg-[#8897c5] bg-[#6E7DAB] rounded-lg text-base leading-none text-white p-4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#7185c0]"
                >
                  {loading ? (
                    <Icons.Spinner className="w-5 h-5 animate-spin" />
                  ) : (
                    'Join us'
                  )}
                </button>
              </form>{' '}
              {errors?.email != null && !error && (
                <span className="-mt-3 text-sm text-red-600 capitalize">
                  {errors?.email?.message}
                </span>
              )}
              {error != null && (
                <span className="-mt-3 text-sm text-red-600 capitalize">
                  {errorMessage ??
                    'There was an error subscribing to the newsletter.'}
                </span>
              )}
              {success?.status && (
                <span className="-mt-3 text-sm text-green-600 capitalize">
                  {success?.message}
                </span>
              )}
            </div>
          </div>
          <Slideshow />
        </div>
      </div>
    </div>
  );
}
