import { useContext, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Market from '../../components/dashboard/Market';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { GlobalContext, GlobalContextType } from '../../context/GlobalContext';
import ErrorMessage from '../../components/ui/error-fallback';
import React from 'react';

const MarketPage = () => {
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const { likedAssets } = useContext(GlobalContext) as GlobalContextType;
  const [marketAssets, setMarketAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getMarketAsset = useCallback(async () => {
    setLoading(true);
    try {
      let payload = {
        pageNumber: 0,
        pageSize: 50,
        category: 6,
      };
      let res = await axiosPrivate.post(`asset/search-assets`, payload);

      // Use map to create a new array with modified objects
      const modifiedData = res.data.data.map((y: any) => {
        // Use Object.assign or spread syntax to create a shallow copy of the object
        const modifiedObject = { ...y };

        if (likedAssets.find((n: any) => n === y.id)) {
          modifiedObject.liked = true;
        } else {
          modifiedObject.liked = false;
        }

        return modifiedObject;
      });

      setMarketAssets(modifiedData);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setIsError(true);
      setErrorMessage(
        err.response?.data?.responseMessage || 'An error occurred'
      );
    }
  }, [axiosPrivate, likedAssets]);

  useEffect(() => {
    if (location.state && location.state.search) {
      setMarketAssets(() => location.state.search);
    } else {
      getMarketAsset();
    }
  }, [getMarketAsset, location.state]);

  const refresh = () => {
    location.state = null;
    window.history.replaceState({}, '');
    getMarketAsset();
  };

  if (isError) {
    return <ErrorMessage message={errorMessage} refetch={getMarketAsset} />;
  }

  return (
    <Market
      assets={marketAssets}
      search={location.state && location.state.search ? true : false}
      refresh={refresh}
      isLoading={loading}
    />
  );
};

export default MarketPage;
