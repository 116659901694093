import { useNavigate } from 'react-router-dom';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SkeletonCard from '../ui/skeleton-card';
import AssetCard from '../ui/asset-card';
import React from 'react';
import { Icons } from '../../assets/icons';
import ContainerLayout from '../core/containerLayout';

interface MarketProps {
  assets: any;
  search: any;
  refresh: any;
  isLoading: boolean;
}

const Market: React.FC<MarketProps> = ({
  isLoading,
  assets,
  search,
  refresh,
}) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <ContainerLayout className="gap-0 md:gap-2">
      <div className="flex items-center justify-between w-full gap-4 px-4 md:px-0">
        <Button
          variant="ghost"
          className="p-0 bg-transparent cursor-pointer"
          onClick={goBack}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          <h4 className="font-medium text-steel-gray dark:text-white">
            {search ? 'Search Results' : 'Most Recent'}
          </h4>
        </Button>
        {!!search && (
          <button onClick={refresh} className="text-destructive">
            Clear result
          </button>
        )}
      </div>
      <div className="flex flex-col w-full gap-4 px-4 md:px-0">
        {isLoading ? (
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-2 gap-4 xl:grid-cols-4 2xl:grid-cols-5 lg:grid-cols-3 sm:grid-cols-3">
                {Array(12)
                  .fill(0)
                  .map((_, i) => (
                    <SkeletonCard key={i} />
                  ))}
              </div>
            </div>
          </div>
        ) : assets?.length > 0 ? (
          <div className="grid grid-cols-2 gap-x-2 gap-y-4 sm:gap-4 xl:grid-cols-4 2xl:grid-cols-5 lg:grid-cols-3 xs:grid-cols-3">
            {assets.map((asset: any, j: number) => (
              <AssetCard key={j} asset={asset ?? []} />
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center my-20 md:my-40">
            <Icons.EmptyTableIcon />
            <p className="-mt-5 text-base">{'No asset found'}</p>
          </div>
        )}
      </div>
    </ContainerLayout>
  );
};

export default Market;
