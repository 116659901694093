import React, { useEffect, useState } from 'react';
import { useGetOtp, useVerifyUser } from '../../../helpers/api/useAuth';
import { Label } from '../../../components/ui/label';
import { Input } from '../../../components/ui/input';
import { cn } from '../../../lib/utils';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../components/ui/dialog';
import { toast } from 'sonner';
import { IUser } from '../../../interface/auth/api';
import { Button } from '../../ui/button';
import { Icons } from '../../../assets/icons';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

type Props = {
  user: IUser;
  onOpen: (show: boolean) => void;
  isOpen: boolean;
};

const UpdatePhoneNumberDialog: React.FC<Props> = ({ user, onOpen, isOpen }) => {
  const axiosPrivate = useAxiosPrivate();
  const [step, setStep] = useState<number>(1);
  const [otp, setOtp] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [accountForm, setAccountForm] = useState({
    phone: user?.phone,
    timeZone: user?.timeZone,
  });

  const getOtp = useGetOtp();

  const {
    isError: verificationError,
    isLoading: verifying,
    isSuccess,
    mutate,
    errorMessage: verificationErrorMessage,
  } = useVerifyUser();

  useEffect(() => {
    if (isSuccess) {
      toast.success('Phone changed successfully');
      onOpen(false);
      setStep(1);
      setOtp('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (verificationError) {
      toast.error(verificationErrorMessage || `Verification fail`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationError]);

  const handleChange = (e: any) => {
    setOtp(e.target.value);
  };

  const HandleVerification = async (e: any) => {
    e.preventDefault();
    let payload = {
      email: user?.email,
      otp: otp,
    };
    mutate(payload);
  };

  const update = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axiosPrivate.patch(`user/user`, accountForm);
      setLoading(false);
      setStep(2);
    } catch (err: any) {
      setLoading(false);
      toast.error(err.response?.data?.responseMessage || 'An error occurred');
    }
  };
  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        onOpen(false);
      }}
    >
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Update Your Account Details</DialogTitle>
        </DialogHeader>

        {step === 1 ? (
          <div>
            <Label htmlFor="phone">Phone Number</Label>
            <Input
              id="phone"
              type="text"
              defaultValue={user?.phone}
              onChange={(e) =>
                setAccountForm((prev) => ({
                  ...prev,
                  phone: e.target.value,
                }))
              }
            />
          </div>
        ) : (
          <div className="relative flex flex-col gap-1.5">
            <Label htmlFor="phone">Verify phone number</Label>
            <Input
              id="phone"
              type="phone"
              value={otp}
              placeholder="Enter code"
              className="h-12"
              autoComplete="off"
              onChange={(e) => handleChange(e)}
              endIcon={
                <div
                  onClick={() => {
                    toast.promise(
                      getOtp.mutateAsync({
                        otpType: 'Phone',
                      }),
                      {
                        loading: 'Sending otp...',
                        success: 'Otp sent successfully',
                        error: getOtp.errorMessage,
                      }
                    );
                  }}
                >
                  <p className="text-sm cursor-pointer">Get Code</p>
                </div>
              }
            />
          </div>
        )}

        <DialogFooter className="flex items-center justify-between w-full gap-3 mt-auto">
          <Button
            className="p-3.5 h-12 text-sm rounded-lg w-full"
            disabled={verifying}
            variant="secondary"
            type="button"
            onClick={(e) => onOpen(false)}
          >
            Close
          </Button>
          <Button
            disabled={
              loading ||
              verifying ||
              (step === 2 && otp.length < 6) ||
              user?.phone === accountForm?.phone
            }
            className={cn(
              'rounded-lg text-sm w-full font-medium disabled:opacity-50 outline-none p-3.5 h-12 flex items-center justify-center'
            )}
            type="button"
            onClick={(e) => {
              if (step === 1) {
                update(e);
              } else {
                HandleVerification(e);
              }
            }}
          >
            {loading || verifying ? (
              <Icons.Spinner className="w-6 h-6 animate-spin" />
            ) : (
              'Update'
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default UpdatePhoneNumberDialog;
