import { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { toast } from 'sonner';
import { Input } from '../../ui/input';
import { Icons } from '../../../assets/icons';
import { Label } from '../../ui/label';
import { useGetOtp, useVerifyUser } from '../../../helpers/api/useAuth';
import React from 'react';
import {
  OnboardContext,
  OnboardContextType,
} from '../../../context/OnboardContext';
import {
  GlobalContext,
  GlobalContextType,
} from '../../../context/GlobalContext';
import { useQueryClient } from '@tanstack/react-query';
import { useCookies } from 'react-cookie';
import { SectionEnum } from '../../../pages/register/register';
import { routes } from '../../../utils/route';
import { Button } from '../../ui/button';

const Verification = ({ next }: { next: (arg0: SectionEnum) => void }) => {
  const cookies = useCookies()[0];
  const location = useLocation();
  const queryClient = useQueryClient();
  const { dispatch } = useContext(OnboardContext) as OnboardContextType;
  const { users } = useContext(GlobalContext) as GlobalContextType;
  const user = users;
  const { isEmailConfirmed, isPhoneNumberConfirmed } = user ?? {};
  const axiosPrivate = useAxiosPrivate();
  const [verification, setVerification] = useState({
    email: '',
    phone: '',
  });
  const [section, setSection] = useState<string>(
    location.state?.subsection || 'phone'
  );
  const navigate = useNavigate();
  const {
    data,
    isError,
    isLoading: verifying,
    isSuccess,
    mutate,
    errorMessage,
  } = useVerifyUser();

  const [countdown, setCountdown] = useState<number>(0);

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  const handleGetCode = () => {
    getCode();
    getOtp.mutateAsync({
      otpType: section === 'phone' ? 'Phone' : 'Email',
    });
    setCountdown(30); // Start 60-second countdown
  };

  const renderButton = () => {
    if (getOtp.isLoading) {
      return <Icons.Spinner className="w-4 h-4 animate-spin" />;
    }
    if (countdown > 0) {
      return `${countdown}s`;
    }
    return 'Get Code';
  };

  const getOtp = useGetOtp();
  const getCode = async () => {
    if (process.env.REACT_APP_ENV === 'development') {
      try {
        await axiosPrivate.get(`user/temp-otp/${cookies?.musha_email}`);
      } catch (err: any) {
        console.error(
          err.response?.data?.responseMessage ?? 'An error occurred'
        );
      }
    }
  };

  const handleChange = (e: any) => {
    setVerification((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const HandleVerification = async (e: any) => {
    e.preventDefault();
    let payload = {
      email: cookies?.musha_email,
      otp:
        section.toLowerCase() === 'phone'
          ? verification.phone
          : verification.email,
    };
    mutate(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      queryClient.cancelQueries({
        queryKey: ['get-user-details', cookies?.musha_userId],
      });
      queryClient.setQueryData(
        ['get-user-details', cookies?.musha_userId],
        data
      );
      if (data?.data?.accountStatus?.toLowerCase() === 'active') {
        navigate(routes.profile);
        dispatch({ type: 'REFETCH_USER_DETAILS', payload: true });
      } else {
        setVerification({
          email: '',
          phone: '',
        });
        if (
          section.toLowerCase() === 'phone' &&
          data?.data?.isPhoneConfirmed === true
        ) {
          setSection('email');
        } else if (data?.data?.isEmailConfirmed === true) {
          dispatch({ type: 'REFETCH_USER_DETAILS', payload: true });
          next(SectionEnum.RequiredSteps);
        } else {
          setSection('email'); // Or you can set it to 'phone' if preferred
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data]);

  useEffect(() => {
    if (getOtp.isSuccess) {
      toast.success('Otp sent successfully');
      setCountdown(0);
    }
  }, [getOtp.isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage || `Verification fail`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  if (isEmailConfirmed && isPhoneNumberConfirmed) {
    return <Navigate to={routes.dashboard} replace={true} />;
  }

  return (
    <form
      autoComplete="off"
      onSubmit={HandleVerification}
      className="flex flex-col w-full max-w-sm gap-4 mx-auto"
    >
      <div className="text-center">
        <h4 className="text-2xl font-semibold">Verify Your Account</h4>
        <p className="text-[15px]">Let's get your account verified</p>
      </div>

      <div className="relative flex flex-col gap-1.5">
        <Label htmlFor={section === 'phone' ? 'phone' : 'email'}>
          {section === 'phone'
            ? 'Phone Number Verification'
            : 'Email Verification'}
        </Label>
        <Input
          id={section === 'phone' ? 'phone' : 'email'}
          type="number"
          value={section === 'phone' ? verification.phone : verification.email}
          placeholder="Enter code"
          autoComplete="off"
          onChange={(e) => handleChange(e)}
          endIcon={
            <Button
              type="button"
              size="sm"
              variant="secondary"
              className="text-sm font-medium"
              onClick={handleGetCode}
              disabled={countdown > 0 || getOtp.isLoading}
            >
              {renderButton()}
            </Button>
          }
        />
      </div>
      <Button
        type="submit"
        disabled={
          (section === 'email' && (verifying || verification?.email === '')) ||
          (section === 'phone' && (verifying || verification?.phone === ''))
        }
      >
        {!verifying ? (
          'Submit'
        ) : (
          <Icons.Spinner className="w-6 h-6 animate-spin" />
        )}
      </Button>
      <span className="text-[13px] text-muted-foreground text-center">
        To continue, please click the 'Get Code' to receive your verification
        code.
      </span>
    </form>
  );
};

export default Verification;
