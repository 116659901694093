import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Icons } from '../../../assets/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Switch } from '../../ui/switch';
import { Avatar, AvatarFallback, AvatarImage } from '../../ui/avatar';
import ThemeSwitcher from '../../ui/theme-switcher';
import React, { useState } from 'react';
import { Building2Icon } from 'lucide-react';
import { routes } from '../../../utils/route';
import UpdatePhoneNumberDialog from './updatePhoneNumberDialog';

const Profile = ({
  user,
  set2Fa,
  is2fa,
  logout,
}: {
  user: any;
  set2Fa: (twofa: boolean) => void;
  is2fa: boolean;
  logout: () => void;
}) => {
  const navigate = useNavigate();
  let profilePicture = user && user.profilePicture;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const firstName = user?.firstname;
  const lastName = user?.lastname;
  const fullName =
    firstName && lastName ? `${firstName} ${lastName}` : firstName || lastName;

  return (
    <div className="flex flex-col w-full gap-4 px-4 md:px-0">
      <div className="w-full p-4 rounded-xl bg-primary dark:bg-[#6E7DAA]">
        <div className="flex items-center justify-center gap-3 bg-inherit">
          <Avatar className="w-20 h-20">
            <AvatarImage src={profilePicture} className="object-cover" />
            <AvatarFallback>
              {user?.accountType.toLowerCase() === 'corporate'
                ? user?.companyName?.charAt(0)
                : fullName.charAt(0)}
            </AvatarFallback>
          </Avatar>
          <div className="text-white bg-inherit">
            <h3 className="font-bold bg-inherit">
              {user?.accountType.toLowerCase() === 'corporate'
                ? user?.companyName
                : fullName || 'Not Available'}
            </h3>
            <p className="text-sm text-[#D7D7D7] bg-inherit">
              {user?.accountType || 'Not Available'}
            </p>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center gap-4 px-0 md:p-4">
        <div
          className="relative p-4 bg-white dark:bg-transparent dark:border-[#3b3a3a] dark:border rounded-xl h-[282px] w-[222.63px] flex flex-col items-center justify-center hover:border "
          style={{
            boxShadow:
              '0px 14.8421049118042px 44.52631378173828px 0px #0000000A',
          }}
        >
          <div className="flex flex-col items-center gap-4">
            <div className="bg-[#F8F8FB] dark:bg-transparent dark:border justify-center rounded-full w-[59.37px] h-[59.37px] flex items-center flex-shrink-0">
              <Building2Icon className="text-primary dark:text-white" />
            </div>
            <div className="text-center">
              <h5 className="text-steel-gray dark:text-[#ffffff] text-base sm:text-lg font-medium">
                My Assets
              </h5>
              <p className="text-sm text-light-grey sm:text-base">
                Access your assets
              </p>
            </div>
          </div>

          <div className="absolute bottom-5">
            <Link to={routes.myAsset}>
              <span className="inline-flex items-center gap-2 text-sm text-primary sm:text-base">
                View <FontAwesomeIcon icon={faArrowRight} />
              </span>
            </Link>
          </div>
        </div>
        <div
          style={{
            boxShadow:
              '0px 14.8421049118042px 44.52631378173828px 0px #0000000A',
          }}
          className="relative p-4 bg-white dark:bg-transparent dark:border-[#3b3a3a] dark:border hover:border rounded-xl h-[282px] w-[222.63px] flex flex-col items-center justify-center"
        >
          <div className="bg-[#F8F8FB] dark:bg-transparent dark:border justify-center rounded-full w-[59.37px] h-[59.37px] flex items-center flex-shrink-0">
            <Icons.AssetIcon className="text-primary dark:text-white" />
          </div>
          <div className="mt-3 text-center">
            <h5 className="text-steel-gray dark:text-[#ffffff]  text-base sm:text-lg font-medium">
              Saved Assets
            </h5>
            <p className="text-sm text-light-grey sm:text-base">
              Access your saved assets
            </p>
          </div>
          <div className="absolute bottom-5">
            <Link to={routes.savedAsset}>
              <span className="inline-flex items-center gap-2 text-sm text-primary sm:text-base">
                View <FontAwesomeIcon icon={faArrowRight} />
              </span>
            </Link>
          </div>
        </div>
      </div>
      <h4 className="text-steel-gray dark:text-[#ffffff] text-base sm:text-[20px] text-left mt-5">
        More
      </h4>

      <div
        className="flex flex-col w-full gap-5 p-4 bg-white dark:border-[#3b3a3a] border dark:bg-transparent rounded-xl"
        style={{
          boxShadow:
            '0px 14.612793922424316px 43.838382720947266px 0px #0000000A',
        }}
        onClick={() => setIsOpen(true)}
      >
        <div className="flex items-center justify-between gap-4 cursor-pointer">
          <div className="flex items-center gap-4">
            <div className="bg-[#F8F8FB] dark:bg-transparent dark:border justify-center rounded-full w-[59.37px] h-[59.37px] flex items-center  flex-shrink-0">
              <Icons.UserIcon className="text-primary dark:text-white" />
            </div>
            <div className="flex flex-col">
              <h5 className="text-steel-gray dark:text-[#ffffff] font-medium text-base sm:text-lg">
                My Account
              </h5>
              <p className="text-sm text-light-grey sm:text-base">
                Update your account details
              </p>
            </div>
          </div>
          <Icons.ArrowDown className="-rotate-90" />
        </div>
        <div
          onClick={() => navigate(routes.orderHistory)}
          className="flex items-center justify-between gap-4 cursor-pointer"
        >
          <div className="flex items-center gap-4">
            <div className="bg-[#F8F8FB] dark:bg-transparent dark:border justify-center rounded-full w-[59.37px] h-[59.37px] flex items-center flex-shrink-0">
              <Icons.MarketIcon className="text-steel-gray dark:text-white" />
            </div>
            <div className="flex flex-col">
              <h5 className="text-steel-gray dark:text-[#ffffff] font-medium text-base sm:text-lg">
                Order History
              </h5>
              <p className="text-sm text-light-grey sm:text-base">
                View your transaction history
              </p>
            </div>
          </div>
          <Icons.ArrowDown className="-rotate-90" />
        </div>
        <div className="flex items-center justify-between gap-4 cursor-pointer">
          <div className="flex items-center gap-4">
            <div className="bg-[#F8F8FB] dark:bg-transparent dark:border justify-center rounded-full w-[59.37px] h-[59.37px] flex items-center flex-shrink-0">
              <Icons.LockIcon className="text-steel-gray dark:text-white" />
            </div>
            <div className="flex flex-col">
              <h5 className="text-steel-gray dark:text-[#ffffff] font-medium text-base sm:text-lg">
                Dark Mode
              </h5>
              <p className="text-sm text-light-grey sm:text-base">
                Adjust your display preferences for comfort
              </p>
            </div>
          </div>
          <ThemeSwitcher />
        </div>
        <div className="flex items-center justify-between gap-4 cursor-pointer">
          <div className="flex items-center gap-4">
            <div className="bg-[#F8F8FB] dark:bg-transparent dark:border justify-center rounded-full w-[59.37px] h-[59.37px] flex items-center flex-shrink-0">
              <Icons.TwoFactorIcon />
            </div>
            <div className="flex flex-col">
              <h5 className="text-steel-gray dark:text-[#ffffff] font-medium text-base sm:text-lg">
                Two-factor Authentication
              </h5>
              <p className="text-sm text-light-grey sm:text-base">
                Further secure your account for safety
              </p>
            </div>
          </div>

          <Switch checked={is2fa} onCheckedChange={set2Fa} />
        </div>
        <div
          onClick={logout}
          className="flex items-center justify-between gap-4 cursor-pointer"
        >
          <div className="flex items-center gap-4">
            <div className="bg-[#F8F8FB] dark:bg-transparent dark:border justify-center rounded-full w-[59.37px] h-[59.37px] flex items-center flex-shrink-0">
              <Icons.Logout2 className="text-steel-gray dark:text-white" />
            </div>
            <div className="flex flex-col">
              <h5 className="text-base font-medium sm:text-lg text-destructive">
                Log out
              </h5>
              <p className="text-sm text-destructive sm:text-base">
                Safely exit your account
              </p>
            </div>
          </div>
          <svg
            width="17"
            height="8"
            viewBox="0 0 17 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="-rotate-90 text-destructive"
          >
            <path
              d="M15.5137 0.999268L8.49869 7.00052L1.48665 0.999267"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <UpdatePhoneNumberDialog user={user} isOpen={isOpen} onOpen={setIsOpen} />
    </div>
  );
};

export default Profile;
