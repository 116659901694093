import { useNavigate } from 'react-router-dom';
import Carousel from '../ui/global-carousel';
import SkeletonCard from '../ui/skeleton-card';
import AnalyticsEmpty from '../ui/analytics';
import AssetCard from '../ui/asset-card';
import React from 'react';
import ContainerLayout from '../core/containerLayout';
import { routes } from '../../utils/route';

const HomeGallery = ({
  assets,
  isLoading,
}: {
  assets: any;
  isLoading: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <ContainerLayout className="p-4 md:py-6 md:px-5">
      {isLoading ? (
        <>
          <div className="flex flex-col w-full gap-4">
            <div className="flex items-center justify-between ">
              <div className="w-32 h-3 bg-gray-200 rounded-lg animate-pulse" />
              <div className="w-24 h-3 bg-gray-200 rounded-lg animate-pulse" />
            </div>
            <div className="flex gap-4 overflow-x-hidden">
              {Array(8)
                .fill(0)
                .map((_, i) => (
                  <SkeletonCard key={i} />
                ))}
            </div>
          </div>

          <div className="flex flex-col w-full gap-4">
            <div className="flex items-center justify-between">
              <div className="w-32 h-3 bg-gray-200 rounded-lg animate-pulse" />
              <div className="w-24 h-3 bg-gray-200 rounded-lg animate-pulse" />
            </div>
            <div className="flex gap-4 overflow-x-hidden">
              {Array(8)
                .fill(0)
                .map((_, i) => (
                  <SkeletonCard key={i} />
                ))}
            </div>
          </div>

          <div className="flex flex-col w-full gap-4">
            <div className="flex items-center justify-between">
              <div className="w-32 h-3 bg-gray-200 rounded-lg animate-pulse" />
              <div className="w-24 h-3 bg-gray-200 rounded-lg animate-pulse" />
            </div>
            <div className="flex gap-4 overflow-x-hidden">
              {Array(8)
                .fill(0)
                .map((_, i) => (
                  <SkeletonCard key={i} />
                ))}
            </div>
          </div>
        </>
      ) : (
        assets &&
        assets.length > 0 &&
        assets.map((item: any, i: number) => {
          return (
            <div key={i} className="flex flex-col w-full gap-4">
              <div className="flex items-center justify-between">
                <h4 className="text-[15px] sm:text-base text-steel-gray   font-medium sm:font-semibold">
                  {item?.name ?? ''}
                </h4>
                {item.assets.length > 0 && (
                  <button
                    className="text-steel-gray dark:text-white text-[15px] sm:text-base cursor-pointer"
                    onClick={() => {
                      navigate(`${routes.assetCategory}/${item.categoryId}`, {
                        state: {
                          name: item?.name ?? '',
                        },
                      });
                    }}
                  >
                    See All
                  </button>
                )}
              </div>
              {item.assets.length > 0 ? (
                <Carousel key={i}>
                  {item.assets.map((asset: any, j: number) => (
                    <AssetCard
                      key={j}
                      asset={asset ?? []}
                      similar={item?.name}
                      similarId={item.categoryId}
                    />
                  ))}
                </Carousel>
              ) : (
                <div className="relative flex gap-4 overflow-x-hidden">
                  <div className="z-20 flex flex-col gap-4">
                    <div className="flex gap-4 overflow-x-hidden scroll">
                      {Array(8)
                        .fill(0)
                        .map((_, i) => (
                          <SkeletonCard key={i} />
                        ))}
                    </div>
                  </div>
                  <AnalyticsEmpty message="No asset found" />
                </div>
              )}
            </div>
          );
        })
      )}
    </ContainerLayout>
  );
};

export default HomeGallery;
