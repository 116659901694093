import React, { ReactNode } from 'react';
import { cn } from '../../lib/utils';

interface MyComponentProps {
  children: ReactNode;
  className?: string;
}

const ContainerLayout: React.FC<MyComponentProps> = ({
  children,
  className,
}) => {
  return (
    <div className="p-0 md:p-4 dark:border-[#3b3a3a] dark:border bg-[#F2F2F2] dark:bg-transparent rounded-[40px] relative min-h-96 h-full">
      <div
        className={cn(
          'flex flex-col items-start h-full gap-4 p-0 bg-white dark:bg-transparent md:p-5 rounded-[30px]',
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default ContainerLayout;
