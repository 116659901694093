import PhoneInput from 'react-phone-input-2';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../ui/form';
import * as React from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export type IKey = {
  [key: string]: any;
};

const PhoneNumber = ({
  form,
  fieldName,
}: {
  form: any;
  fieldName?: string;
}) => {
  const name = 'phone';
  const [formData, setFormData] = React.useState({ phoneNumber: '', iso2: '' });
  const lngCode = 'ng';

  const isValidPhoneNumber = (value: string, iso2: any): boolean => {
    if (value.length <= 3) {
      return true; // Return false if the value is empty or undefined
    }

    const IOS = iso2.toUpperCase();
    const parsedNumber = parsePhoneNumberFromString(value, IOS);

    // Check if the parsed number is valid and return the result
    return parsedNumber ? parsedNumber.isValid() : false;
  };

  return (
    <FormField
      name="phone"
      control={form.control}
      rules={{
        required: {
          value: true,
          message: 'Phone number is required',
        },
        validate: (value) =>
          isValidPhoneNumber(value, formData?.iso2) || 'Invalid phone number',
      }}
      render={({ field }) => (
        <FormItem className="relative ">
          <FormLabel className="font-normal">
            {fieldName || 'Phone number'}
          </FormLabel>
          <FormControl>
            <PhoneInput
              placeholder={'Enter your phone Number'}
              inputClass="flex h-12 p-3 outline-none ring-0 items-center justify-center text-black dark:text-black w-full rounded-lg border border-[#00000029] bg-transparent text-sm placeholder:text-opacity-50 disabled:cursor-not-allowed disabled:opacity-50"
              inputProps={{
                name,
              }}
              enableSearch={true}
              value={field.value}
              onChange={(value: any, country: any) => {
                field.onChange(value);
                const lngISOCode = (country as any)?.countryCode;
                setFormData({ phoneNumber: value, iso2: lngISOCode });
              }}
              containerStyle={{ outline: 'none' }}
              inputStyle={{
                width: '100%',
              }}
              country={lngCode}
              isValid={(inputNumber: string, country: any) => {
                const lngISOCode = (country as any)?.iso2;
                const isValid = isValidPhoneNumber(inputNumber, lngISOCode);
                return isValid;
              }}
              specialLabel=""
            />
          </FormControl>
          <p className="text-xs text-orange-500">
            Please input your WhatsApp number
          </p>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default PhoneNumber;
