import React, { useState } from 'react';
import ContainerLayout from '../../core/containerLayout';
import { Avatar, AvatarFallback, AvatarImage } from '../../ui/avatar';
import { useGetUser } from '../../../helpers/api/useAsset';
import { Icons } from '../../../assets/icons';
import ErrorMessage from '../../ui/error-fallback';
import UpdatePhoneNumberDialog from './updatePhoneNumberDialog';
import { Button } from '../../ui/button';
import FieldDisplay from './fieldDisplay';
import { ResetPasswordDialog } from './resetPasswordDialog';

type Props = {};

const ProfileUpdate = (props: Props) => {
  const { payload, isLoading, isError, errorMessage, refetch } = useGetUser();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isPasswordOpen, setIsPasswordOpen] = useState<boolean>(false);
  const user = payload;
  let profilePicture = user && user.profilePicture;

  const firstName = user?.firstname;
  const lastName = user?.lastname;
  const fullName =
    firstName && lastName ? `${firstName} ${lastName}` : firstName || lastName;

  if (isLoading) {
    return (
      <div className="flex justify-center mt-10">
        <Icons.Spinner />
      </div>
    );
  }

  if (isError) {
    return <ErrorMessage message={errorMessage} refetch={refetch} />;
  }

  return (
    <ContainerLayout>
      <div className="w-full p-4 rounded-xl bg-primary dark:bg-[#6E7DAA]">
        <div className="flex items-center justify-center gap-3 bg-inherit">
          <Avatar className="w-20 h-20">
            <AvatarImage src={profilePicture} className="object-cover" />
            <AvatarFallback>
              {user?.accountType.toLowerCase() === 'corporate'
                ? user?.companyName?.charAt(0)
                : fullName.charAt(0)}
            </AvatarFallback>
          </Avatar>
          <div className="text-white bg-inherit">
            <h3 className="font-bold bg-inherit">
              {user?.accountType.toLowerCase() === 'corporate'
                ? user?.companyName
                : fullName || 'Not Available'}
            </h3>
            <p className="text-sm text-[#D7D7D7] bg-inherit">
              {user?.accountType || 'Not Available'}
            </p>
          </div>
        </div>
      </div>
      <div className="max-w-[600px] flex flex-col items-center justify-center gap-6 w-full mx-auto sm:p-6">
        <div className="flex flex-col items-start justify-start w-full gap-6">
          <div>
            <h3 className="text-2xl font-semibold text-steel-gray">
              Company details
            </h3>
            <p className="text-sm text-[#D7D7D7] hidden">
              Update your company details here. Click update when you're done.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center w-full gap-4">
            <FieldDisplay
              label="Company Name"
              value={user?.companyName}
              isDisabled
            />
            <FieldDisplay
              label="Company Email"
              value={user?.email}
              isDisabled
            />

            <div className="flex items-end justify-between w-full gap-2">
              <FieldDisplay label="Company Phone" value={`+${user?.phone}`} />

              <Button
                className="mb-1 hover:bg-slate-100"
                variant="ghost"
                onClick={() => setIsOpen(true)}
              >
                <Icons.EditIcon className="w-5 h-5 text-primary" />
              </Button>
            </div>
            <div className="flex items-end justify-between w-full gap-2">
              <FieldDisplay
                label="Password"
                value={'************'}
                isDisabled
              />

              <Button
                variant="ghost"
                className="mb-1 hover:bg-slate-100"
                onClick={() => setIsPasswordOpen(true)}
              >
                <Icons.EditIcon className="w-5 h-5 text-primary" />
              </Button>
            </div>
          </div>
          <span className="-mt-4 text-sm sm:text-base text-destructive">
            To update your company name or email, please contact us.
          </span>
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-6">
          <div>
            <h3 className="text-2xl font-semibold text-steel-gray">
              Company Representative details
            </h3>
          </div>
          <div className="flex flex-col items-center justify-center w-full gap-4">
            <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2">
              <FieldDisplay
                label="First Name"
                value={user?.firstName}
                isDisabled
              />
              <FieldDisplay
                label="Last Name"
                value={user?.lastName}
                isDisabled
              />
            </div>{' '}
            <FieldDisplay label="Email" value={`${user?.email}`} />
            <FieldDisplay label="Phone" value={`+${user?.phone}`} />
            <Button
              className="w-full"
              size="lg"
              variant="default"
              onClick={() => {}}
            >
              Edit Representative Details
            </Button>
          </div>
        </div>
      </div>{' '}
      <UpdatePhoneNumberDialog user={user} isOpen={isOpen} onOpen={setIsOpen} />
      <ResetPasswordDialog isOpen={isPasswordOpen} onOpen={setIsPasswordOpen} />
    </ContainerLayout>
  );
};

export default ProfileUpdate;
