import React from 'react';
import { Label } from '../../ui/label';
import { cn } from '../../../lib/utils';

interface FieldDisplayProps {
  label: string;
  value: string | number | null | undefined;
  isDisabled?: boolean;
}

const FieldDisplay: React.FC<FieldDisplayProps> = ({
  label,
  value,
  isDisabled,
}) => {
  return (
    <div className="flex flex-col items-start justify-start w-full gap-1">
      <Label>{label}</Label>
      <span
        className={cn(
          'flex items-center justify-start w-full h-12 p-2 text-sm text-black border rounded-xl border-input bg-slate-100',
          {
            'bg-slate-100': !isDisabled,
          }
        )}
      >
        {value || 'N/A'}
      </span>
    </div>
  );
};

export default FieldDisplay;
