import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Profile from '../../components/dashboard/profile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../components/ui/button';
import { useNavigate } from 'react-router-dom';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useLogout } from '../../helpers/api/useAuth';
import { useGetUser } from '../../helpers/api/useAsset';
import { toast } from 'sonner';
import { Icons } from '../../assets/icons';
import ErrorMessage from '../../components/ui/error-fallback';
import React from 'react';
import ContainerLayout from '../../components/core/containerLayout';

const ProfilePage = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { payload, isLoading, isError, errorMessage, refetch } = useGetUser();
  const user = payload;
  const [is2fa, setIs2fa] = useState<boolean>(
    user?.is2faEnabled === true ? true : false
  );

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (user) {
      setIs2fa(user?.is2faEnabled === true ? true : false);
    }
  }, [user]);

  const set2fa = async (value: boolean) => {
    try {
      // Update the state
      setIs2fa(value);

      if (value) {
        await axiosPrivate.post(`user/enable-2fa`);
      } else {
        await axiosPrivate.post(`user/disable-2fa`);
      }
    } catch (err: any) {
      // Store the current value before making changes
      let previousIs2fa = is2fa;
      // If the API call fails, revert the state back to the previous value
      setIs2fa(previousIs2fa);

      toast.error(err.response?.data?.responseMessage || 'An error occurred');
    }
  };

  const { logoutUser: logout } = useLogout();

  if (isLoading) {
    return (
      <div className="flex justify-center mt-10">
        <Icons.Spinner />
      </div>
    );
  }

  if (isError) {
    return <ErrorMessage message={errorMessage} refetch={refetch} />;
  }

  return (
    <ContainerLayout className="gap-0 py-6">
      <div className="hidden w-full px-4 py-2 md:px-0">
        <Button
          id="top"
          variant="ghost"
          className="p-0 text-sm md:text-base"
          onClick={goBack}
        >
          <FontAwesomeIcon icon={faAngleLeft} /> Back
        </Button>
      </div>
      <Profile user={user} set2Fa={set2fa} is2fa={is2fa} logout={logout} />
    </ContainerLayout>
  );
};

export default ProfilePage;
