import { BASE_URL, useUrls } from '../useUrls';
import { IErrorResponseType } from '../../interface/common/error';
import { useMutation, useQuery } from '@tanstack/react-query';
import { errorConnection } from './useAuth';
import { useCookies } from 'react-cookie';
import { INewAsset } from '../../components/dashboard/addAsset/schema';
import useAxiosWithInterceptor from '../axiosConfig';
import { AxiosResponse } from 'axios';
import { IUserResponse } from '../../interface/user';
import { getErrorMessage } from '../../utils/helper';

const url = `${BASE_URL}/api/v1`;

export const useGetUser = () => {
  const { user } = useUrls();
  const cookies = useCookies()[0];
  const axiosInstance = useAxiosWithInterceptor();
  const {
    isLoading,
    isError,
    error,
    data,
    refetch,
    isSuccess,
    isFetching,
    isFetched,
  } = useQuery({
    queryKey: ['get-user-details', cookies?.musha_userId],
    queryFn: async () => {
      const response =
        cookies?.musha_userId &&
        (await axiosInstance.get<AxiosResponse<IUserResponse>>(
          `${user}/${cookies?.musha_userId}`
        ));
      return response && response.data;
    },
  });

  const typedError = error as unknown as IErrorResponseType;
  const errorString = Array.isArray(typedError?.response?.data?.responseMessage)
    ? typedError?.response?.data?.responseMessage[0]
    : typedError?.response?.data?.responseMessage || errorConnection;

  const isNotAuthorize =
    typedError?.response && typedError?.response?.status === 401;

  return {
    isLoading,
    isError,
    refetch,
    payload: data?.data,
    errorMessage: errorString,
    isSuccess,
    isNotAuthorize,
    isFetching,
    isFetched,
  };
};

export const useGetAssets = () => {
  const { asset } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const { isPending, isError, error, data, refetch } = useQuery({
    queryKey: ['get-assets'],
    queryFn: async () => {
      const response = await axiosInstance.get(`${asset}/dashboard`);
      return response.data;
    },
    refetchOnWindowFocus: true,
  });

  const typedError = error as unknown as IErrorResponseType;
  const errorString = Array.isArray(typedError?.response?.data?.responseMessage)
    ? typedError?.response?.data?.responseMessage[0]
    : typedError?.response?.data?.responseMessage || errorConnection;
  return {
    isLoading: isPending,
    isError,
    data,
    refetch,
    errorMessage: errorString,
  };
};

export const useGetAssetById = (id: string) => {
  const { asset } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const {
    isLoading,
    isError,
    error,
    data,
    refetch,
    isRefetching,
    isFetching,
    isRefetchError,
    isSuccess,
  } = useQuery({
    queryKey: ['asset-details', id],
    queryFn: async () => {
      const response = await axiosInstance.get(`${asset}/${id}`);
      return response.data;
    },
  });

  const typedError = error as unknown as IErrorResponseType;
  const errorString = Array.isArray(typedError?.response?.data?.responseMessage)
    ? typedError?.response?.data?.responseMessage[0]
    : typedError?.response?.data?.responseMessage || errorConnection;
  return {
    isLoading,
    isRefetching,
    isFetching,
    isError:
      isSuccess && data?.data.length === 0 ? data?.data.length === 0 : isError,
    data,
    errorMessage: data?.data.length === 0 ? 'Asset not found' : errorString,
    isRefetchError,
    refetch,
  };
};

export const useGetAssetPrices = (id: string) => {
  const { asset } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const {
    isLoading,
    isError,
    error,
    data,
    refetch,
    isRefetching,
    isFetching,
    isRefetchError,
    isSuccess,
  } = useQuery({
    queryKey: ['asset-prices', id],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${asset}/get-asset-prices/${id}`
      );
      return response.data;
    },
  });

  const typedError = error as unknown as IErrorResponseType;
  const errorString = Array.isArray(typedError?.response?.data?.responseMessage)
    ? typedError?.response?.data?.responseMessage[0]
    : typedError?.response?.data?.responseMessage || errorConnection;
  return {
    isPriceLoading: isLoading,
    isRefetching,
    isFetching,
    isPriceError: isError,
    prices: data,
    errorMessage: errorString,
    isRefetchError,
    refetchPrice: refetch,
    priceSuccess: isSuccess,
  };
};

export const useGetAssetByCategory = () => {
  const { asset } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const { mutate, isPending, isSuccess, isError, error, data } = useMutation({
    mutationFn: (payload: any) => {
      return axiosInstance.post(`${asset}/Search-assets`, payload);
    },
  });

  const typedError = error as unknown as IErrorResponseType;
  const errorString = Array.isArray(typedError?.response?.data?.responseMessage)
    ? typedError?.response?.data?.responseMessage[0]
    : typedError?.response?.data?.responseMessage || errorConnection;
  return {
    mutate,
    isLoading: isPending,
    isSuccess,
    isError:
      isSuccess && data?.data.length === 0 ? data?.data.length === 0 : isError,
    data,
    errorMessage: errorString,
  };
};

export const useGetMyAsset = () => {
  const { asset } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const { mutate, isPending, isSuccess, isError, error, data } = useMutation({
    mutationFn: (payload: any) => {
      return axiosInstance.post(`${asset}/get-my-asset`, payload);
    },
  });

  const typedError = error as unknown as IErrorResponseType;
  const errorMessage = getErrorMessage(typedError);
  return {
    mutate,
    isLoading: isPending,
    isSuccess,
    isError:
      isSuccess && data?.data.length === 0 ? data?.data.length === 0 : isError,
    data,
    errorMessage:
      typedError?.response?.status === 403
        ? 'You are not authorized to access this resource.'
        : typedError?.response?.status === 404
        ? 'Asset not found'
        : errorMessage,
  };
};

export const useCancelOrder = (id: string) => {
  const { asset } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const { isPending, isSuccess, isError, error, data } = useQuery({
    queryKey: ['cancel-order', `${id}`],
    queryFn: async () => {
      const response = await (id &&
        axiosInstance.get(`${asset}/cancel-order/${id}`));
      return response && response?.data;
    },
    enabled: !!id,
  });

  const typedError = error as unknown as IErrorResponseType;
  const errorString = Array.isArray(typedError?.response?.data?.responseMessage)
    ? typedError?.response?.data?.responseMessage[0]
    : typedError?.response?.data?.responseMessage || errorConnection;
  return {
    isLoading: isPending,
    isSuccess,
    isError,
    data,
    errorMessage: errorString,
  };
};

export const useGetLga = (id: string) => {
  const axiosInstance = useAxiosWithInterceptor();
  const { isLoading, isSuccess, isError, data } = useQuery({
    queryKey: ['get-lga', id],
    queryFn: async () => {
      const response = await (id !== 'undefined' &&
        axiosInstance.get(`${url}/lookup/lga/${id}`));
      return response && response.data;
    },
    enabled: !!id && id !== 'undefined',
  });

  return {
    loadingLga: isLoading,
    isSuccess,
    isError,
    lga: data?.data,
  };
};

export const useGetState = (id: string) => {
  const axiosInstance = useAxiosWithInterceptor();
  const { isLoading, isSuccess, isError, data } = useQuery({
    queryKey: ['get-states', id],
    queryFn: async () => {
      const response = await (id !== 'undefined' &&
        axiosInstance.get(`${url}/lookup/states/${id}`));
      return response && response.data;
    },
    enabled: !!id && id !== 'undefined',
  });

  return {
    loadingStates: isLoading,
    isSuccess,
    isError,
    states: data?.data,
  };
};

export const useGetCountry = () => {
  const axiosInstance = useAxiosWithInterceptor();
  const { isLoading, isSuccess, isError, data } = useQuery({
    queryKey: ['get-country'],
    queryFn: async () => {
      const response = await axiosInstance.get(`${url}/lookup/countries`);
      return response.data;
    },
  });

  return {
    loadingCountries: isLoading,
    isSuccess,
    isError,
    countries: data?.data,
  };
};

export const useGetAssetConfig = (propertyTypeId: string, stateId: string) => {
  const axiosInstance = useAxiosWithInterceptor();
  const {
    isLoading,
    isSuccess,
    isRefetching,
    isError,
    data,
    error,
    refetch,
    isFetched,
  } = useQuery({
    queryKey: ['get-asset-config', propertyTypeId, stateId],
    queryFn: async () => {
      if (propertyTypeId === undefined || stateId === undefined) {
        return null;
      }

      const response = await (propertyTypeId !== '' &&
        stateId !== '' &&
        axiosInstance.get(
          `${url}/lookup/asset-config/${propertyTypeId}/${stateId}`
        ));
      return response && response.data;
    },
    enabled: propertyTypeId !== '' && stateId !== '',
    retry: 1,
  });

  const typedError = error as unknown as IErrorResponseType;
  const errorMessage = Array.isArray(
    typedError?.response?.data?.responseMessage
  )
    ? typedError?.response?.data?.responseMessage[0]
    : typedError?.response?.data?.responseMessage || errorConnection;

  return {
    loadingAssetConfig: isLoading,
    isSuccess,
    isError,
    assetConfig: data?.data ?? {},
    errorMessage,
    isRefetching,
    refetch,
    isFetched,
  };
};

export const useGetPropertyTypes = () => {
  const axiosInstance = useAxiosWithInterceptor();
  const { isPending, isSuccess, isError, data } = useQuery({
    queryKey: ['get-property-type'],
    queryFn: async () => {
      const response = await axiosInstance.get(`${url}/lookup/property-types`);
      return response.data;
    },
  });

  return {
    loadingTypes: isPending,
    isSuccess,
    isError,
    propertyTypes: data?.data,
  };
};

export const useCalculateAssetUnit = (
  availableUnits: number,
  totalAssetValue: number,
  propertyTypeId: string,
  stateId: string
) => {
  const axiosInstance = useAxiosWithInterceptor();
  let temp: string;
  if (Number(availableUnits) > 0) {
    temp = `?totalAsset=${totalAssetValue}&availableUnits=${availableUnits}`;
  } else {
    temp = `?totalAsset=${totalAssetValue}`;
  }

  const { isLoading, isSuccess, isError, data, error } = useQuery({
    queryKey: [
      'calculate-asset-price',
      propertyTypeId,
      totalAssetValue,
      availableUnits,
    ],
    queryFn: async () => {
      if (
        !propertyTypeId ||
        !stateId ||
        totalAssetValue === undefined || // Check if totalAssetValue is undefined
        isNaN(totalAssetValue) || // Check if totalAssetValue is NaN (not a number)
        totalAssetValue <= 0
      ) {
        throw new Error('Invalid parameters');
      }

      return axiosInstance.get(
        `${url}/lookup/asset-calculation/${propertyTypeId}/${stateId}${temp}`
      );
    },
    retry: 1,
  });

  const typedError = error as unknown as IErrorResponseType;
  const errorString = Array.isArray(typedError?.response?.data?.responseMessage)
    ? typedError?.response?.data?.responseMessage[0]
    : typedError?.response?.data?.responseMessage || errorConnection;
  return {
    isLoading,
    isSuccess,
    isError,
    data: data?.data?.data,
    errorMessage: errorString,
  };
};

export const useUploadAsset = () => {
  const { asset } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const { mutate, isPending, isSuccess, isError, error } = useMutation({
    mutationFn: (payload: INewAsset) => {
      return axiosInstance.post(`${asset}`, payload);
    },
    mutationKey: ['Upload asset'],
  });

  const typedError = error as unknown as IErrorResponseType;
  const errorString = Array.isArray(typedError?.response?.data?.responseMessage)
    ? typedError?.response?.data?.responseMessage[0]
    : typedError?.response?.data?.responseMessage || errorConnection;
  return {
    isUploadingAsset: isPending,
    uploadAssetSuccess: isSuccess,
    uploadAssetError: isError,
    mutate,
    errorMessage: errorString,
  };
};


export const useEditAsset = (id: string) => {
  const { asset } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const { mutate, isPending, isSuccess, isError, error } = useMutation({
    mutationFn: (payload: INewAsset) => {
      return axiosInstance.put(`${asset}`, payload);
    },
    mutationKey: ['Edit asset', id],
  });

  const typedError = error as unknown as IErrorResponseType;
  const errorString = Array.isArray(typedError?.response?.data?.responseMessage)
    ? typedError?.response?.data?.responseMessage[0]
    : typedError?.response?.data?.responseMessage || errorConnection;
  return {
    isUpdatingAsset: isPending,
    updateAssetSuccess: isSuccess,
    updateAssetError: isError,
    mutate,
    errorMessage: errorString,
  };
};
