import { TOKEN } from '../../utils/token';
import { IErrorResponseType } from '../../interface/common/error';
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { useUrls } from '../useUrls';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import useAxiosWithInterceptor from '../axiosConfig';
import { getErrorMessage } from '../../utils/helper';
import { routes } from '../../utils/route';

export const errorConnection =
  'Error connecting to server, please check your internet connection';

export const useLogin = (email: string) => {
  const setCookie = useCookies()[1];
  const navigate = useNavigate();
  const { loginUrl } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const { mutate, isPending, isSuccess, isError, error, data } = useMutation({
    mutationFn: (payload: { username: string; password: string }) => {
      return axiosInstance.post(loginUrl, payload);
    },

    onSuccess: (res: any) => {
      const loginDetails = res?.data?.data;
      // const setCookie = (name: string, value: string) => {
      //   document.cookie = `${name}=${value || ''}; path=/`;
      // };

      if (res?.data?.responseMessage.toLowerCase() === 'otp sent.') {
        // toast.success('Success', {
        //   description: 'Successful! Please enter your OTP.',
        // });
        navigate(routes.verifyAccount, {
          state: {
            email: email,
          },
        });
      }
      setCookie(TOKEN.ACCESS, loginDetails.jwtToken);
      setCookie(TOKEN.TOKENEXP, loginDetails.jwtTokenExpiry);
      setCookie(TOKEN.ID, loginDetails.userId);
      setCookie(TOKEN.EMAIL, loginDetails?.email);
    },
  });
  const typedError = error as unknown as IErrorResponseType;
  const errorMessage = getErrorMessage(typedError);
  return {
    mutate,
    isLoading: isPending,
    isSuccess,
    isError,
    errorMessage,
    data,
  };
};

export const useVerifyUser = () => {
  const { user } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const { mutate, isPending, isSuccess, isError, error, data } = useMutation({
    mutationFn: (payload: any) => {
      return axiosInstance.post(
        `${user}/validate-account-confirmation`,
        payload
      );
    },
  });
  const errorMessage = getErrorMessage(error);

  return {
    mutate,
    isLoading: isPending,
    isSuccess,
    isError,
    error,
    errorMessage,
    data: data?.data,
  };
};

export const useGetOtp = () => {
  const { user } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const { mutateAsync, isPending, isSuccess, isError, error, data } =
    useMutation({
      mutationFn: (payload: any) => {
        return axiosInstance.post(
          `${user}/initiate-account-confirmation`,
          payload
        );
      },
    });
  const errorMessage = getErrorMessage(error);
  return {
    mutateAsync,
    isLoading: isPending,
    isSuccess,
    isError,
    errorMessage,
    data,
  };
};

export const useLogout = () => {
  const router = useNavigate();
  const removeCookie = useCookies()[2];
  const queryClient = new QueryClient();

  const clearUserData = () => {
    removeCookie(TOKEN.ACCESS);
    removeCookie(TOKEN.ID);
    removeCookie(TOKEN.TOKENEXP);
    removeCookie(TOKEN.EMAIL);
    sessionStorage.clear();
    localStorage.removeItem('createAssetData');
  };

  const logoutUser = async () => {
    clearUserData();
    router('/login');
    queryClient.removeQueries();
    queryClient.clear();
    queryClient.resetQueries();
  };

  return { logoutUser };
};

export const useVerifyAccount = () => {
  const setCookie = useCookies()[1];
  const { otpUrl } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const { mutate, isPending, isSuccess, isError, error, data } = useMutation({
    mutationFn: (payload: any) => {
      return axiosInstance.post(otpUrl, payload);
    },
    onSuccess: (res: any) => {
      const loginDetails = res?.data?.data;
      setCookie(TOKEN.TOKENEXP, loginDetails.jwtTokenExpiry);
      setCookie(TOKEN.ACCESS, loginDetails.jwtToken);
      setCookie(TOKEN.ID, loginDetails.userId);
    },
  });

  const errorMessage = getErrorMessage(error);

  return {
    mutate,
    isLoading: isPending,
    isSuccess,
    isError,
    errorMessage,
    data: data?.data?.data,
  };
};

export const useGetTimeZones = () => {
  const { user } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const { isPending, isError, error, data, refetch, isFetching } = useQuery({
    queryKey: ['get-timezones'],
    queryFn: async () => {
      const response = await axiosInstance.get(`${user}/timezone`);
      return response.data;
    },
    refetchOnWindowFocus: false,
  });

  const errorMessage = getErrorMessage(error);
  return {
    isLoading: isPending || isFetching,
    isError,
    data,
    refetch,
    errorMessage,
  };
};

export const useGetRequiredDocuments = (isEnabled: boolean) => {
  const { requiredDocuments } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const { isPending, isError, error, data, refetch } = useQuery({
    queryKey: ['get-required-documents'],
    queryFn: async () => {
      const response = await axiosInstance.get(requiredDocuments);
      return response.data;
    },
    enabled: isEnabled,
  });

  const errorMessage = getErrorMessage(error);
  return {
    isLoading: isPending,
    isError,
    data,
    refetch,
    errorMessage,
  };
};
