import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Check, Eye, EyeOff, X } from 'lucide-react';
import { Button } from '../../ui/button';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../ui/form';
import { Input } from '../../ui/input';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../ui/dialog';

interface PasswordFormData {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const ResetPasswordDialog: React.FC<{
  isOpen: boolean;
  onOpen: (isOpen: boolean) => void;
}> = ({ isOpen, onOpen }) => {
  const form = useForm<PasswordFormData>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  const [passwordValid, setPasswordValid] = useState({
    has8Characters: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
  });

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const newPassword = form.watch('newPassword');

  const checkPassword = (password: string) => {
    setPasswordValid({
      has8Characters: password.length >= 8,
      hasUppercase: /[A-Z]/.test(password),
      hasLowercase: /[a-z]/.test(password),
      hasNumber: /[0-9]/.test(password),
    });
  };

  useEffect(() => {
    checkPassword(newPassword || '');
  }, [newPassword]);

  const getValidationTitle = (key: string) => {
    const titles = {
      has8Characters: '8 characters',
      hasUppercase: 'Uppercase (A-Z)',
      hasLowercase: 'Lowercase (a-z)',
      hasNumber: 'Number (0-9)',
    };
    return titles[key as keyof typeof titles] || '';
  };

  const onSubmit = (data: PasswordFormData) => {
    console.log(data);
    // Handle password update logic here
  };

  useEffect(() => {
    form.reset({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={() => onOpen(false)}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Password Update</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form className="grid gap-2" onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="currentPassword"
              rules={{ required: 'Current Password is required' }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Current Password</FormLabel>
                  <Input
                    type={showCurrentPassword ? 'text' : 'password'}
                    placeholder="Enter current password"
                    {...field}
                    endIcon={
                      <Button
                        type="button"
                        onClick={() =>
                          setShowCurrentPassword(!showCurrentPassword)
                        }
                        variant="ghost"
                        size="sm"
                      >
                        {showCurrentPassword ? (
                          <EyeOff className="w-4 h-4 text-body" />
                        ) : (
                          <Eye className="w-4 h-4 text-body" />
                        )}
                      </Button>
                    }
                  />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="newPassword"
              rules={{ required: 'New Password is required' }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>New Password</FormLabel>
                  <Input
                    type={showNewPassword ? 'text' : 'password'}
                    placeholder="Enter new password"
                    {...field}
                    endIcon={
                      <Button
                        type="button"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        variant="ghost"
                        size="sm"
                      >
                        {showNewPassword ? (
                          <EyeOff className="w-4 h-4 text-body" />
                        ) : (
                          <Eye className="w-4 h-4 text-body" />
                        )}
                      </Button>
                    }
                  />
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="grid grid-cols-2 gap-4 py-1 text-xs">
              {Object.entries(passwordValid).map(([key, value]) => (
                <div key={key} className="flex items-center gap-1">
                  <span className="text-gray-500">
                    {value ? (
                      <Check className="w-3 h-3 text-green-500" />
                    ) : (
                      <X className="w-3 h-3 text-red-500" />
                    )}
                  </span>
                  <span className="text-gray-500">
                    {getValidationTitle(key)}
                  </span>
                </div>
              ))}
            </div>

            <FormField
              control={form.control}
              name="confirmPassword"
              rules={{
                validate: (value) =>
                  value === newPassword || 'Passwords do not match',
                required: 'Confirm Password is required',
              }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Confirm New Password</FormLabel>
                  <Input
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Confirm new password"
                    {...field}
                    endIcon={
                      <Button
                        type="button"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        variant="ghost"
                        size="sm"
                      >
                        {showConfirmPassword ? (
                          <EyeOff className="w-4 h-4 text-body" />
                        ) : (
                          <Eye className="w-4 h-4 text-body" />
                        )}
                      </Button>
                    }
                  />
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              disabled={
                !form.formState.isDirty ||
                !Object.values(passwordValid).every(Boolean)
              }
              type="submit"
              size="lg"
              className="w-full mt-3"
            >
              Update Password
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
