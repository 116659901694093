import { BrowserRouter, useRoutes } from 'react-router-dom';
import { Suspense, useContext, useEffect, useState } from 'react';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { GlobalContext, GlobalContextType } from './context/GlobalContext';
import { appRoutes } from './utils/route';

const AppRoutes = () => {
  const element = useRoutes(appRoutes);
  return element;
};

function App() {
  const { dispatch } = useContext(GlobalContext) as GlobalContextType;
  const [isDarkMode] = useState(() =>
    localStorage.getItem('darkMode') === 'true' ? true : false
  );
  useEffect(() => {
    dispatch({ type: 'TOGGLE_DARK_MODE', payload: isDarkMode });
  }, [dispatch, isDarkMode]);

  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <AppRoutes />
        </Suspense>
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;
