import { axiosPrivate } from '../api/axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useRefreshToken from './useRefreshToken';
import { getAuthToken } from '../helpers/axiosConfig';

const useAxiosPrivate = () => {
  const navigate = useNavigate();
  const refresh = useRefreshToken();
  const token = getAuthToken();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        // console.log('authMiddleware', user, user?.jwtToken)
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error: any) => {
        if (error?.response?.status === 401) {
          navigate('/login');
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [navigate, refresh, token]);

  return axiosPrivate;
};

export default useAxiosPrivate;
