import axios from 'axios';
import { BASE_URL } from './useUrls';
import { TOKEN } from '../utils/token';
import { toast } from 'sonner';
import { useLogout } from './api/useAuth';
import { useRef } from 'react';

export const setAuthToken = (token: string) => {
  if (token) {
    // Apply to every request
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common['Authorization'];
  }
};

export const getAuthToken = () => {
  const getCookie = (name: string): string | null => {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  };
  if (typeof window !== 'undefined') {
    return getCookie(TOKEN.ACCESS);
  }
};

export const getAuthorizationHeader = () => `Bearer ${getAuthToken()}`;

const useAxiosWithInterceptor = () => {
  const { logoutUser: logout } = useLogout();
  const hasToastShown = useRef(false);
  // const hasShownToast2 = useRef(false);
  // Creating axios client, preConfigured with base url and other fields
  const axiosInstance = axios.create({
    baseURL: BASE_URL as string,
    timeout: 100000,
    timeoutErrorMessage:
      'Your request timed out, please check your internet connection',
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
      common: {
        Authorization: getAuthorizationHeader(),
      },
    },
  });

  // Intercept responses
  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      // Handle responses with status code 401 (Unauthorized)
      if (
        error.response &&
        error.response.status === 401 &&
        !hasToastShown.current
      ) {
        hasToastShown.current = true; // Set the flag
        toast.error(
          'Unauthorized access. Please log in again or contact support.'
        );
        logout(); // Call the logout function from your hook

        // Optionally, reset the flag after a certain period if needed
        setTimeout(() => {
          hasToastShown.current = false;
        }, 3000); // Reset after 3 seconds, adjust as needed
      }

      // Handle responses with status code 403 (Forbidden)
      // if (
      //   error.response &&
      //   error.response.status === 403 &&
      //   !hasShownToast2.current
      // ) {
      //   hasShownToast2.current = true; // Set the flag
      //   toast.error('You are not authorized to access this resource.');

      //   // Optionally, reset the flag after a certain period if needed
      //   setTimeout(() => {
      //     hasShownToast2.current = false;
      //   }, 3000); // Reset after 3 seconds, adjust as needed
      // }

      return Promise.reject(error); // Using Promise.reject directly
    }
  );

  axiosInstance.interceptors.request.use(
    async (config: any) => {
      config.headers.Authorization = getAuthorizationHeader();
      return config;
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};

export const cancelTokenSource = axios.CancelToken.source();

export default useAxiosWithInterceptor;
